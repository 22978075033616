export const calculatePrice = (val) => {
  let rate = 121;
  let discount = 0;
  let price = 0;
  const initialPrice = parseFloat(val) + 3;
  const percentage = (initialPrice * 3.5) / 100;
  const percentagePrice = initialPrice + percentage;
  const discountPercentValue = (initialPrice * 1) / 100;
  if (val >= 300) {
    const totalPrice = parseInt((percentagePrice + 20) * rate) + 500;
    price = totalPrice + 210;
    discount = totalPrice - discountPercentValue.toFixed() - 1320;
  }
  if (val >= 200 && val < 300) {
    const totalPrice = parseInt((percentagePrice + 15) * rate) + 500;
    price = totalPrice;
    discount = totalPrice - 1300;
  }
  if (val >= 100 && val < 200) {
    const totalPrice = parseInt((percentagePrice + 13) * rate) + 500;
    price = totalPrice;
    discount = totalPrice - 1296;
  }
  if (val > 50 && val < 100) {
    const totalPrice = parseInt((percentagePrice + 7) * rate) + 500;
    price = totalPrice;
    discount = totalPrice - 642;
  }
  if (val > 20 && val <= 50) {
    const totalPrice = parseInt((percentagePrice + 5) * rate) + 500;
    price = totalPrice;
    discount = totalPrice - 513;
  }
  if (val <= 20) {
    const totalPrice = parseInt((percentagePrice + 3) * rate) + 500;
    price = totalPrice;
    discount = totalPrice - 593;
  }

  return {
    price: price.toLocaleString(),
    discount: discount,
  };
};

export const calculateUsdtPrice = (val) => {
  const creationFee = 2;
  const rechargeFee = 3.5;
  let initialPrice = parseFloat(val);
  let percentageValue = (initialPrice * rechargeFee) / 100;
  const price = initialPrice + percentageValue + creationFee;

  return {
    price: price.toFixed(2),
  };
};

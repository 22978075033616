import { useEffect, useState } from "react";

import AddressDetail from "./AddressDetail";

const CardDetail = ({
  card_number,
  cvv,
  holder_name,
  expired_date,
  address,
  country,
  city,
  state,
  street,
  zipcode,
}) => {
  const [isCopied, setIsCopied] = useState("");

  const handleCopy = (value) => {
    setIsCopied(value);
    const textarea = document.createElement("textarea");
    textarea.value = value;
    document.body.appendChild(textarea);

    textarea.select();
    document.execCommand("copy");

    document.body.removeChild(textarea);
  };
  useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => {
        setIsCopied(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [isCopied]);
  return (
    <div className="min-w-[250px] flex flex-col gap-2  border text-sm mt-2">
      <AddressDetail
        title={"Card Number"}
        value={card_number}
        isCopied={isCopied !== card_number}
        onCopy={() => handleCopy(card_number)}
      />
      <AddressDetail
        title={"Holder Name"}
        value={holder_name}
        isCopied={isCopied !== holder_name}
        onCopy={() => handleCopy(holder_name)}
      />
      <AddressDetail
        title={"CVV"}
        value={cvv}
        isCopied={isCopied !== cvv}
        onCopy={() => handleCopy(cvv)}
      />
      <AddressDetail
        title={"Expired Date"}
        value={expired_date}
        isCopied={isCopied !== expired_date}
        onCopy={() => handleCopy(expired_date)}
      />
      <AddressDetail
        title={"Address"}
        value={address}
        isCopied={isCopied !== address}
        onCopy={() => handleCopy(address)}
      />
      <AddressDetail
        title={"Street"}
        value={street}
        isCopied={isCopied !== street}
        onCopy={() => handleCopy(street)}
      />
      <AddressDetail
        title={"City"}
        value={city}
        isCopied={isCopied !== city}
        onCopy={() => handleCopy(city)}
      />

      <AddressDetail
        title={"State"}
        value={state}
        isCopied={isCopied !== state}
        onCopy={() => handleCopy(state)}
      />
      <AddressDetail
        title={"Country"}
        value={country}
        isCopied={isCopied !== country}
        onCopy={() => handleCopy(country)}
      />

      <AddressDetail
        title={"ZipCode"}
        value={zipcode}
        isCopied={isCopied !== zipcode}
        onCopy={() => handleCopy(zipcode)}
      />
    </div>
  );
};
export default CardDetail;

import DataNotFoundVector from "./Icons/DataNotFoundVector";

const NoRecordFound = ({ title, body }) => {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center p-6">
      <div>
        <DataNotFoundVector height={"300"} width={"200"} />
      </div>
      <div className="font-bold text-2xl">{title}</div>
      <div className="max-w-[500px] py-3 text-center">{body}</div>
    </div>
  );
};

export default NoRecordFound;

const SingleStepVerification = ({ step, isActive }) => {
  return (
    <div
      className={`h-12 w-12 lg:h-20 lg:w-20 rounded-full border ${
        isActive ? "border-[#1f7e95]" : "border-slate-400"
      }  flex justify-center items-center`}
    >
      <div
        className={`h-10 w-10 lg:h-16 lg:w-16 ronded-full ${
          isActive ? "bg-[#1f7e95]" : "bg-slate-400"
        } rounded-full flex justify-center items-center ${isActive?'text-white':'text-slate-700'} lg:text-xl`}
      >
        {step}
      </div>
    </div>
  );
};

export default SingleStepVerification;

const TransactionCard = ({
  icon,
  reason,
  date,
  amount,
  status,
  sign,
  failurity,
}) => {
  return (
    <div className="w-full shadow-md rounded-md p-3">
      <div className="w-full flex justify-between ">
        <div className="flex gap-1 items-start">
          <div>{icon}</div>
          <div className="flex flex-col">
            <div className=" text-md font-semibold text-slate-600">
              {reason}
            </div>
            <div className="text-slate-400 text-sm">{date}</div>
          </div>
        </div>
        <div className="flex flex-col items-end">
          <div className=" text-md font-semibold text-slate-600">
            {sign + amount} USD
          </div>
          <div
            className={`${
              status === "Failed"
                ? "text-red-500"
                : status === "Pending"
                ? "text-yellow-500"
                : "text-green-500"
            } text-sm `}
          >
            {status}
          </div>
        </div>
      </div>
      {status === "Failed" && (
        <div className="text-red-500 text-xs w-full flex justify-end">
          {failurity}
        </div>
      )}
    </div>
  );
};

export default TransactionCard;

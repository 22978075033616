import { baseUrl, token } from "../constants/constants";

export const getTransaction = (id) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${baseUrl}transactions/${id}`, requestOptions);
};

import { useState } from "react";
import { baseUrl, primaryColor, secondaryColor } from "../constants/constants";
import { NavLink, useNavigate } from "react-router-dom";
import { MainButton } from "../components";
import HidePasswordEye from "../components/Icons/HidePasswordEye";
import ShowPasswordEye from "../components/Icons/ShowPasswordEye";
import { logo } from "../assets";
import FormSideUi from "../components/formSideUi";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onButtonClick();
    }
  };
  const handleToggleVisibility = () => {
    setShowPassword(!showPassword);
  };
  function onButtonClick() {
    setEmailError("");
    setPasswordError("");
    if (isLoading) return;

    if ("" === email && "" === password) {
      setEmailError("Your email is required");
      setPasswordError("Password field is required!");
      return;
    }
    if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email.trim())) {
      // if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      setEmailError("Please enter a valid email");
      return;
    }

    if ("" === email) {
      setPasswordError("Your email is required");
      return;
    }

    if ("" === password) {
      setPasswordError("Password field is required!");
      return;
    }
    setIsLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: email.trim(),
      password: password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      //   redirect: "follow",
    };

    fetch(`${baseUrl}login`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          setIsLoading(false);
        }
        return response.json();
      })
      .then((result) => {
        if (result.status !== true) {
          setError(result.message);
          return;
        }
        localStorage.setItem("token", result.token);
        // window.sessionStorage.setItem("token", result.token);
        // console.log(result?.token, "usertoken 1");

        navigate("/virtual-cards", {
          state: { token: result.token },
          replace: true,
        });

        window.location.reload();
      })
      .catch((error) => {
        setIsLoading(false);
        return;
        // console.log("error", error)
      });
  }
  return (
    <>
      <div className="w-full flex">
        <FormSideUi />

        <div className="w-full flex-col items-center ">
          <div
            className={`min-h-screen flex flex-col items-center justify-center`}
          >
            <div className="flex  sm:px-10 lg:hidden items-center">
              <img className="h-10 md:h-16 " src={logo} alt="" />
              <div
                className={`text-xl md:text-4xl font-bold text-[${primaryColor}]`}
              >
                YEHADIGITAL
              </div>
            </div>
            <div className="w-full flex justify-center py-10">
              <div className="w-[90%] max-w-[600px] bg-white   px-5 sm:px-10 py-10">
                <h1
                  style={{ color: secondaryColor }}
                  className={`text-4xl font-bold text-[${secondaryColor}] text-center flex flex-col items-center gap-2 justify-center`}
                >
                  Login
                </h1>
                <h1
                  style={{ color: secondaryColor }}
                  className={`text-md font-bold text-[${secondaryColor}] text-center flex flex-col items-center gap-2 justify-center py-2`}
                >
                  Welcome to Yehadigital
                  <hr className="w-full md:w-[30%]" />
                </h1>

                <div className="py-10 flex justify-center space-y-3 text-lg text-slate-600 ">
                  <div className="flex justify-center flex-col items-start w-full ">
                    <div className="flex items-start flex-col w-full gap-1">
                      <label>Email</label>
                      <input
                        value={email}
                        placeholder="Email Address"
                        onChange={(ev) => setEmail(ev.target.value)}
                        className="border border-slate-600/50 rounded-md px-3 py-2 w-full "
                        onKeyDown={handleKeyDown}
                      />
                      <label className="text-red-500 text-sm">
                        {emailError}
                      </label>
                    </div>
                    <br />
                    <div className="flex items-start flex-col w-full gap-1 ">
                      <div className="flex justify-between w-full items-center">
                        <label>Password</label>
                        <div className="text-[#2cb4d5]">
                          <NavLink
                            //   onClick={handleMenuToggle}
                            to="/forget-password"
                            className={({ isActive, isPending }) =>
                              isPending
                                ? "pending"
                                : isActive
                                ? "text-[#2cb4d5]"
                                : ""
                            }
                          >
                            Forgot?
                          </NavLink>
                        </div>
                      </div>

                      <div className="flex w-full border border-slate-600/50 rounded-md pr-2">
                        <input
                          value={password}
                          placeholder="Password"
                          onChange={(ev) => setPassword(ev.target.value)}
                          className=" px-3 py-2 w-full border-none outline-none rounded-md "
                          type={showPassword ? "text" : "password"}
                          onKeyDown={handleKeyDown}
                        />
                        <button onClick={handleToggleVisibility}>
                          {showPassword ? (
                            <HidePasswordEye className={"h-6 w-6"} />
                          ) : (
                            <ShowPasswordEye className={"h-6 w-6"} />
                          )}
                        </button>
                      </div>

                      <label className="text-red-500 text-sm">
                        {passwordError}
                      </label>
                    </div>
                    <label className="text-red-500 text-sm text-center">
                      {error}
                    </label>

                    <br />
                    <MainButton
                      label={"Login"}
                      onClick={onButtonClick}
                      isLoading={isLoading}
                    />

                    <div className="flex justify-center w-full mt-2 gap-3">
                      Don't have an account?
                      <NavLink to={"/sign-up"} className={"text-[#2cb4d5]"}>
                        SignUp
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

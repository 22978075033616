const InputField = ({
  label,
  value,
  onchange,
  error,
  placeholder,
  type,
  disabled,
}) => {
  return (
    <div className="flex items-start flex-col w-full gap-1">
      <label>
        {label} <span className="text-red-500">*</span>
      </label>

      <input
        disabled={disabled}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={onchange}
        className={`border ${
          error !== "" ? "border-red-500" : "border-slate-600/50"
        } ${disabled ? "border-slate-600/20" : "border-slate-600/50"} ${
          disabled ? "bg-gray-200/30" : ""
        } rounded-md px-3 py-2 w-full`}
      />
      <label className="text-red-500 text-sm">{error}</label>
    </div>
  );
};

export default InputField;

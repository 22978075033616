import { useNavigate } from "react-router-dom";
import { ArrowLeft } from "../components";

const TOS = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="w-full flex flex-col items-center ">
        <div className="w-[90%] sm:w-[70%] text-slate-600 space-y-4">
          <div className="w-full py-4 text-2xl text-slate-600 font-bold flex items-center space-x-2">
            <div
              onClick={() => {
                navigate(-1);
              }}
              className="sm:cursor-pointer"
            >
              <ArrowLeft className={"h-6 w-6"} />
            </div>

            <div className="">Terms of service</div>
          </div>
          <hr />
          <div className="py-5 text-left">
            These terms of service (the “Agreement”) is between you and
            Yehadigital, and governs your use of our products and services
            available through our website (the “Site” ) and our mobile or web
            applications (each an “App”), including any service we provide to
            you (“Services”)
          </div>
          <div className="text-xl font-bold text-slate-950  py-2">
            <h1> TERMS</h1>
          </div>
          <div className="text-left  space-y-5 py-2">
            <p>
              By accessing our Services through any means, you accept our terms
              and conditions and agree to be bound by it. If you do not agree
              with any of these terms, you are prohibited from using or
              accessing our Services.
            </p>
            <p>
              We reserve the right to amend, modify or change any portion of
              this Agreement without prior notice to you and without your
              consent. Where any portion of this Agreement is amended or
              modified, such changes shall be effective immediately.
            </p>
            <p>
              We suggest that you review these Agreement periodically for such
              changes and/or modifications. You acknowledge that by accessing
              and using our Services after such change/modifications, you agree
              to these terms as modified.
            </p>
          </div>
          <div className="text-xl font-bold text-slate-950  py-2">
            <h1>ACCOUNTS</h1>
          </div>
          <div>
            <ul className="space-y-3 ">
              <li>
                <p>
                  <label className="font-bold">Eligibility.:</label> If you wish
                  to access our Services, you must create an account (an
                  "Account") with us. You will be required to satisfy our
                  Account opening criteria including any identity verification,
                  screening procedures or other know-your-customer procedures we
                  may apply at our discretion. To open an Account and use our
                  Services, you must be 16 years of age or older and capable of
                  forming a binding contract. We may establish additional
                  eligibility criteria at our discretion. You shall only
                  register one personal Account. In the event of death, payments
                  of all monies/interest in your Account shall be made in
                  accordance with applicable law
                </p>
              </li>
              <li>
                <p>
                  <label className="font-bold"> Information.:</label> Your You
                  must provide all requested information completely and
                  accurately, including your full legal name, email address,
                  phone number, bank verification number, government issued ID
                  and any other information we may require to open or maintain
                  an Account. We may ask you for additional information about
                  you or your transactions from time to time and where required,
                  and may provide this to our Partners in connection with
                  Services. You represent and warrant that any information you
                  provide via our platform is accurate and complete. You hereby
                  authorize us, directly or through our third-party service
                  providers, to make any inquiries necessary to verify your
                  identity or protect you against fraud, including any action
                  reasonably necessary to comply with applicable law or
                  regulations. You agree that the information you provide to us
                  on account registration through our App will be true,
                  accurate, current, and complete.
                </p>
              </li>
              <li>
                <p>
                  <label className="font-bold"> Security.:</label>
                  You are responsible for all activities that occur on your
                  Account, whether or not you know about them. It is important
                  that you keep your access credentials including any password
                  secret and private, and not share these with anyone else. You
                  agree that you shall be solely responsible for any activities
                  or actions under your Account and that we are irrevocably
                  authorised to comply with any instructions received on our
                  platform on your behalf for your use of our Services. Such
                  instructions shall be irrevocably deemed to have been
                  authorised by you. You agree not to disclose your password to
                  any third party and you will notify us immediately if your
                  password is lost or stolen or if you suspect any unauthorised
                  use of your Account..
                </p>
              </li>
              <li>
                <p>
                  <label className="font-bold"> Restrictions.:</label>
                  In order to protect the integrity of our Services, we reserve
                  the right, at any time, in our sole discretion, to block
                  access to our Services from certain places, which may include
                  certain IP addresses and unique device identifiers. We may
                  also block applications for Accounts from certain persons or
                  persons of certain countries where required to do so, or
                  pursuant to our risk appetite and risk management processes.
                  You may not open an Account if you live in certain
                  jurisdictions, or are subject to any sanctions (including UN
                  sanctions or OFAC sanctions) or a citizen of a country, or
                  located in a country, that is subject to sanctions. We may in
                  our sole discretion or as required by law or our third party
                  service providers suspend or prevent any transaction to comply
                  with applicable law or prevent fraud.
                </p>
              </li>
              <li>
                <p>
                  <label className="font-bold">
                    Third-party service providers.:
                  </label>
                  We partner with third parties (“Partners”) to facilitate,
                  provide, or otherwise offer certain parts of our services and
                  certain information from you may be provided to those service
                  providers in connection with our services for our legal,
                  regulatory, or compliance purposes.
                </p>
              </li>
            </ul>
          </div>
          <div className="font-bold text-xl text-slate-950 py-2">
            Use of Yehadigital Virtual Card
          </div>
          <div className="pb-12">
            <ul className="space-y-3 text-md">
              <li>
                One user can only apply for three Yehadigital Virtual Card.
              </li>
              <li>
                Yehadigital may setup and adjust, in its sole discretion, the
                maximum number of under certain Crypto Card, the Monthly Limit
                and Card Limit for risk control and regulatory compliance
                purposes from time to time. Any payment exceeding such Monthly
                Limit and shall be rejected.
              </li>
              <li>
                All Yehadigital Virtual Card remain the property of the Service
                Provider and must be returned upon request. The Service Provider
                may cancel, revoke, repossess or restrict the use of the Virtual
                Card at any time. Yehadigital may decline to authorize or
                reverse charges or suspend Virtual Card for any reason including
                violation of this Agreement, suspected fraud, or
                creditworthiness. Yehadigital also may decline to authorize
                Transactions at merchants characterized by the Service Provider.
                Yehadigital is not responsible for losses resulting from
                declined or reversed Transactions.
              </li>
              <li>
                If Service Provider determines that the Client’s Virtual Card
                receives, or is likely to receive, a disproportionately high
                number of declined transactions, reversals, refunds, disputes,
                fees or other liability, the Client may be subject to certain
                handling fee upon notice via email. The Client agrees to
                terminate the use of the Services if it does not agree to such
                fees or charges.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default TOS;

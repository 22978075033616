import { useNavigate } from "react-router-dom";
import { LandingImage } from "../assets";
import { CheckedText } from "../components";
import { primaryColor, secondaryColor } from "../constants/constants";
import HorizontalScroll from "../components/HorizontalScroll";

export const VirtualCardLanding = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full  h-full flex items-center justify-center ">
      <div className="w-[90%] md:w-[75%] h-full flex flex-col justify-between gap-3">
        <div className=" flex-col relative  justify-center items-center h-full md:flex-row flex w-full gap-3">
          <div className="w-full h-full flex flex-col justify-center items-center gap-6  lg:mt-0">
            <div className="text-center sm:text-left  w-full text-[8vw] sm:text-3xl md:text-4xl xl:text-5xl font-bold py-4">
              <h1 className={`text-[${primaryColor}] min-h-max font-roboto `}>
                Get Your Virtual Cards
              </h1>

              <h1
                style={{ color: secondaryColor }}
                className={`text-[${secondaryColor}] text-xl  sm:text-3xl min-h-max`}
              >
                For International Transactions
              </h1>
            </div>
            <div className="block lg:hidden w-full">
              <img src={LandingImage} alt="" />
            </div>
            <div className="hidden sm:block w-full text-md md:text-lg text-slate-500 p-2">
              Go global with our Virtual Debit Card! Instant and secure
              international payments. Your passport to hassle-free transactions!
            </div>
            <div className="w-full flex flex-col gap-10">
              <div className="grid grid-cols-1 sm:grid-cols-2  space-y-2 justify-around">
                <CheckedText title="Reasonable Price" />
                <CheckedText title="100% Guarantee" />
                <CheckedText title="High Security" />
                <CheckedText title="Great Support" />
              </div>
              <button
                onClick={() => {
                  navigate("/login");
                }}
                className="bg-gradient-to-r bg-animate  from-[#2cb4d5]  to-[#1a6c80] w-[200px] rounded-full text-white py-3 text-lg"
              >
                Get your card
              </button>
            </div>
          </div>
          <div className="w-full h-full lg:flex justify-center items-center hidden ">
            <img src={LandingImage} alt="" className="w-3/4" />
          </div>
        </div>
        <div className="h-full flex items-center flex-1/2 ">
          <HorizontalScroll />
        </div>
      </div>
    </div>
  );
};

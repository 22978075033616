import { baseUrl, token } from "../constants/constants";

export const verifyKycApi = async (
  user_id,
  file_path,
  birth_date,
  id_number,
  address_line1,
  city,
  state,
  country,
  postalCode
) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    user_id: user_id,
    file_path: file_path,
    birth_date: birth_date,
    id_number: id_number,
    address_line1: address_line1,
    city: city,
    state: state,
    country: country,
    postalCode: postalCode,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  let verifitaion = await fetch(`${baseUrl}fill-kyc`, requestOptions);

  return verifitaion.json();
};

export const firstStepKyc = async (birthDate) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    birth_date: birthDate,
  });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  let verifitaion = await fetch(`${baseUrl}fill-kyc`, requestOptions);

  return verifitaion.json();
};

export const secondStepKycApi = async (
  address_line1,
  city,
  state,
  country,
  postalCode
) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    address_line1: address_line1,
    city: city,
    state: state,
    country: country,
    postalCode: postalCode,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  let verifitaion = await fetch(`${baseUrl}second-step`, requestOptions);

  return verifitaion.json();
};

export const thirdStepKycApi = async (document_type, file, id_number) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  var formData = new FormData();
  formData.append('file', file);
  formData.append('Idnumber', id_number);
  formData.append('document_type', document_type);


  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData,
    redirect: "follow",
  };

  let verifitaion = await fetch(`${baseUrl}last-kyc`, requestOptions);

  return verifitaion.json();
};

import MainButton from "./mainButton";

const VerificationMessage = ({ onClick, isLoading, title, img, body }) => {
  return (
    <>
      <div className="w-full h-full flex items-center justify-center">
        <div className="w-[90%] md:w-[50%] bg-white rounded-md border border-slate-100 min-h-[300px] flex flex-col items-center py-6 shadow">
          <div className="text-center font-bold text-slate-700 text-xl py-8">
            {title}
          </div>
          <div className="h-48 w-48 flex justify-center ">
            <img src={img} alt="" />
          </div>
          <div className="p-6 text-center text-slate-700">{body}</div>

          <div className={` px-6 w-full cursor-pointer `}>
            <MainButton
              label={"Send Email"}
              onClick={onClick}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default VerificationMessage;

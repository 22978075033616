import { secondaryColor } from "../constants/constants";
import LoadingSpinner from "./LoadingSpin";

const MainButton = ({ label, onClick, isLoading }) => {
  return (
    <div
      onClick={onClick}
      style={{
        backgroundColor: isLoading ? "#125b6c" : secondaryColor,
      }}
      className={`flex items-center justify-center w-full  rounded-md gap-2 cursor-pointer`}
    >
      {isLoading ? (
        <LoadingSpinner
          className={
            "h-6 w-6 border-t-2 border-l-2 border-r-2 animate-spin rounded-full text-white"
          }
        />
      ) : null}

      <input
        className={`text-white py-2 font-bold cursor-pointer`}
        type="button"
        value={label}
      />
    </div>
  );
};
export default MainButton;

import { useEffect, useState } from "react";
import HidePasswordEye from "./Icons/HidePasswordEye";
import ShowPasswordEye from "./Icons/ShowPasswordEye";

const Balance = ({ balance }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [visibleBalance, setVisibleBalance] = useState("****");
  const handleVisibility = () => {
    setIsVisible(!isVisible);
    setVisibleBalance(isVisible ? balance : "****");
  };
  useEffect(() => {
    setVisibleBalance(isVisible ? balance : "****");
  }, [isVisible, balance]);
  return (
    <div>
      <div className="text-slate-700 text-md flex gap-2 items-center">
        <div>Card Balance</div>
        <div>
          {isVisible ? (
            <div onClick={handleVisibility}>
              <ShowPasswordEye
                className={"w-5 h-5 sm:cursor-pointer text-slate-400"}
              />
            </div>
          ) : (
            <div onClick={handleVisibility}>
              <HidePasswordEye
                className={"w-5 h-5 sm:cursor-pointer text-slate-400"}
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center justify-center">
        <div className="flex w-full gap-2 items-center justify-left ">
          <div className="flex gap-1 items-baseline font-bold text-3xl text-slate-700">
            <div>$</div>
            <div>{visibleBalance}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Balance;

import { baseUrl, token } from "../constants/constants";

export const fetchUserInfo = async (hello) => {
  const token = localStorage.getItem("token");
  if (token == null) return;
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  let userInfo = await fetch(`${baseUrl}client-info`, requestOptions);

  return userInfo.json();
};

export const getCardDetails = () => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  // console.log("users");
  let userString = localStorage.getItem("user");
  let users = JSON.parse(userString);

  fetch(`${baseUrl}get-cards/${users.id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => result)
    .catch((error) => error);
};

export const updateUserInfo = (data) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: data,
    redirect: "follow",
  };

  return fetch(`${baseUrl}update-info`, requestOptions);
};

export const updatePassword = (data) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: data,
    redirect: "follow",
  };

  return fetch(`${baseUrl}update-password`, requestOptions);
};

import { useState } from "react";
import { logo } from "../assets";
import { baseUrl, primaryColor, secondaryColor } from "../constants/constants";
import { MainButton } from "../components";
import { NavLink } from "react-router-dom";
import NewPassword from "./NewPassword";

const VerifyOtp = () => {
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(0);
  //   const navigate = useNavigate();
  const onButtonClick = () => {
    setIsLoading(true);
    setOtpError("");
    if (isLoading) return;

    if ("" === otp) {
      setOtpError("The OTP field is required");
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const email = localStorage.getItem("email");
    var raw = JSON.stringify({
      email: email,
      otp: otp,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${baseUrl}validate-otp`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          // console.log(response);
          setIsLoading(false);
        }
        return response.json();
      })
      .then((result) => {
        // console.log(result);
        if (result.status !== true) {
          setError(result.message);
          return;
        }
        localStorage.setItem("token", result.token);

        // window.sessionStorage.setItem("token", result.token);

        setStep(1);
        // navigate("/new-pass");

        // window.location.reload();
      })
      .catch((error) => {
        // console.log("error", error)
      });
  };
  return (
    <>
      {step === 0 ? (
        <div className="w-full flex-col items-center justify-center">
          <div
            className={`min-h-screen flex flex-col items-center justify-center`}
          >
            <div className="flex  sm:px-20 py-2 items-center lg:hidden">
              <img className="h-10 md:h-16 " src={logo} alt="" />
              <div
                className={`text-2xl md:text-4xl font-bold text-[${primaryColor}]`}
              >
                YEHADIGITAL
              </div>
            </div>
            <div className="w-full flex justify-center py-10">
              <div className="w-[90%] max-w-[600px] bg-white  px-5 sm:px-10 py-10">
                <h1
                  style={{ color: secondaryColor }}
                  className={`text-4xl font-bold text-[${secondaryColor}] text-center flex flex-col items-center gap-2 justify-center`}
                >
                  Enter the OTP code
                </h1>
                <h1
                  style={{ color: secondaryColor }}
                  className={`text-md  text-[${secondaryColor}] text-center flex flex-col items-center gap-2 justify-center py-2`}
                >
                  Enter the OTP code we sent to your mobile number in the field
                  below
                  <hr className="w-full md:w-[30%]" />
                </h1>

                <div className="py-10 flex justify-center space-y-3 text-lg text-slate-600 ">
                  <div className="flex justify-center flex-col items-start w-full ">
                    <div className="flex items-start flex-col w-full gap-1">
                      <input
                        value={otp}
                        placeholder="OTP Code "
                        onChange={(ev) => setOtp(ev.target.value)}
                        className="border border-slate-600/50 rounded-md px-3 py-2 w-full "
                      />
                      <label className="text-red-500 text-sm">{otpError}</label>
                    </div>
                    {/* <br /> */}

                    <label className="text-red-500 text-sm text-center">
                      {error}
                    </label>

                    <br />
                    <MainButton
                      label={"Reset Password"}
                      onClick={onButtonClick}
                      isLoading={isLoading}
                    />

                    <div className="text-[#2cb4d5] mt-2">
                      <NavLink
                        //   onClick={handleMenuToggle}
                        to="/login"
                        className={({ isActive, isPending }) =>
                          isPending
                            ? "pending"
                            : isActive
                            ? "text-[#2cb4d5]"
                            : ""
                        }
                      >
                        Login
                      </NavLink>
                    </div>
                    <div className="flex justify-center w-full mt-2 gap-3">
                      Don't have an account?
                      <NavLink to={"/sign-up"} className={"text-[#2cb4d5]"}>
                        SignUp
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <NewPassword />
      )}
    </>
  );
};

export default VerifyOtp;

import LoadingSpinner from "./LoadingSpin";

const CancelButton = ({label,isLoading,onClick}) => {
  return (
    <div
      onClick={onClick}
      style={{
        backgroundColor: isLoading ? "#6b7280" : "#cbd5e1",
      }}
      className={`flex items-center justify-center w-full border border-slate-500  rounded-md gap-2 cursor-pointer`}
    >
      {isLoading ? (
        <LoadingSpinner
          className={
            "h-6 w-6 border-t-2 border-l-2 border-r-2 animate-spin rounded-full text-white"
          }
        />
      ) : null}

      <input
        className={`text-slate-500 py-2 font-bold cursor-pointer`}
        type="button"
        value={label}
      />
    </div>
  );
};

export default CancelButton;

import { baseUrl, token } from "../constants/constants";

export const fetchCardType = async () => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  let response = await fetch(`${baseUrl}card-type`, requestOptions);
  if (response.ok) return response.json();
  return;
};

export const fetchPaymentMethod = async () => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  let response = await fetch(`${baseUrl}payment-methods`, requestOptions);
  if (response.ok) return response.json();
  return;
};

export const fetchCardDetails = async (hello) => {
  const token = localStorage.getItem("token");
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  // console.log(token, "usertoken");

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${baseUrl}get-cards`, requestOptions);
};

import LoadingSpinner from "./LoadingSpin";

const DeleteModal = ({
  isLoading,
  isChecked,
  handleCheckboxChange,
  onClick,
}) => {
  return (
    <div className="flex flex-col gap-3">
      <div className="flex gap-2">
        <input
          type="checkbox"
          checked={isChecked}
          disabled={isLoading}
          onChange={handleCheckboxChange}
        />
        <div className="py-2">
          You can't receive any consumption refund after the card is deleted.
        </div>
      </div>
      <div>
        <div>Would you still want to delete your card?</div>
        <div
          onClick={onClick}
          className={`text-white ${
            isChecked ? "bg-red-400" : "bg-red-200"
          }  w-full text-center p-2 rounded-xl mt-3 font-bold sm:cursor-pointer flex justify-center gap-2`}
        >
          {isLoading && isChecked ? (
            <LoadingSpinner
              className={
                "h-6 w-6 border-t-2 border-l-2 border-r-2 animate-spin rounded-full text-white"
              }
            />
          ) : null}
          <div>Confirm</div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;

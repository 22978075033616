const ContactDetail = ({ title, content, icon }) => {
  return (
    <div className="w-full grid grid-cols-1 gap-2 sm:grid-cols-2 justify-evenly">
      <div className="flex gap-2 items-center">
        <div>{icon}</div>
        <div>{title}</div>
      </div>
      <div>{content}</div>
    </div>
  );
};

export default ContactDetail;

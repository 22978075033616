import React, { createContext, useContext, useState } from "react";

const BackButtonContext = createContext();

export const BackButtonProvider = ({ children }) => {
  const [providerStep, setProviderStep] = useState(0);
  const [step, setStep] = useState(0);
  const [label, setLabel] = useState(null);
  const [selectedPayment, setSelecedPayment] = useState("");

  return (
    <BackButtonContext.Provider
      value={{
        providerStep,
        setProviderStep,
        step,
        setStep,
        label,
        setLabel,
        selectedPayment,
        setSelecedPayment,
      }}
    >
      {children}
    </BackButtonContext.Provider>
  );
};

export const useBackButton = () => {
  const context = useContext(BackButtonContext);

  return context;
};

import { useState } from "react";
import { logo } from "../assets";
import { baseUrl, primaryColor, secondaryColor } from "../constants/constants";
import ShowPasswordEye from "../components/Icons/ShowPasswordEye";
import HidePasswordEye from "../components/Icons/HidePasswordEye";
import { MainButton } from "../components";
import { useNavigate } from "react-router-dom";

const NewPassword = () => {
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [obscurePassword, setObscurePassword] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [obscureConfirmPassword, setObscureConfirmPassword] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const handlePasswordVisibility = () => {
    setObscurePassword(!obscurePassword);
  };
  const handleConfirmPasswordVisibility = () => {
    setObscureConfirmPassword(!obscureConfirmPassword);
  };
  const onButtonClick = () => {
    const validateField = (field, errorSetter, errorMessage) => {
      if (!field) {
        errorSetter(errorMessage);
        return false;
      }

      return true;
    };

    setPasswordError("");
    setConfirmPasswordError("");

    if (
      !validateField(password, setPasswordError, "Password field is required!")
    ) {
      return;
    }

    if (password.length < 8) {
      setPasswordError("The password must be 8 characters or longer");
      return;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      return;
    }
    setIsLoading(true);

    var myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");
    const email = localStorage.getItem("email");
    var raw = JSON.stringify({
      email: email,
      password: password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${baseUrl}reset-password`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false);
        if (result.status === true) {
          localStorage.setItem("token", result.token);
          // window.sessionStorage.setItem("token", result.token);
          navigate("/");
          window.location.reload();
        }
        if (result.success === false) {
          setErrorMessage("Please use a different email or phone number.");
        }
      })
      .catch((error) => {
        // setIsLoading(false);
        // console.log(error);
        // let val = error.json();
        // console.log("error", val);
      });
  };

  return (
    <div className="w-full h-screen flex-col items-center justify-center">
      <div className={`min-h-screen flex flex-col items-center justify-center`}>
        <div className="flex  sm:px-20 py-2 items-center lg:hidden">
          <img className="h-10 lg:h-16 " src={logo} alt="" />
          <div
            className={`text-2xl lg:text-4xl font-bold text-[${primaryColor}]`}
          >
            YEHADIGITAL
          </div>
        </div>

        <div className="w-full flex justify-center py-10">
          <div className="w-[90%] md:w-[600px] bg-white  px-2 sm:px-10 py-10">
            <h1
              style={{ color: secondaryColor }}
              className={`text-4xl font-bold text-[${secondaryColor}] text-center flex flex-col items-center gap-2 justify-center`}
            >
              Password Reset
            </h1>
            <h1
              style={{ color: secondaryColor }}
              className={`text-md  text-[${secondaryColor}] text-center flex flex-col items-center gap-2 justify-center py-2`}
            >
              Please enter your desired new password in the fields below and
              click on the 'Change Password' button to update your account
              credentials.
              <hr className="w-full md:w-[30%]" />
            </h1>

            <div className="py-10 flex w-full justify-center space-y-3 text-lg text-slate-600 ">
              <div className="flex justify-center flex-col items-center w-full ">
                <div className="flex flex-col  gap-2 w-full space-y-3 md:space-y-0 justify-center items-start">
                  <div className="flex items-start flex-col w-full gap-1">
                    <label>New Password</label>
                    <div
                      className={`w-full flex border border-slate-600/50 rounded-md ${
                        passwordError !== ""
                          ? "border-red-500"
                          : "border-slate-600/50"
                      }`}
                    >
                      <input
                        type={obscurePassword ? "password" : "text"}
                        value={password}
                        placeholder="New password"
                        onChange={(ev) => setPassword(ev.target.value)}
                        className={` outline-none   rounded-md px-3 py-2 w-full`}
                      />
                      <button
                        className="mr-2"
                        onClick={handlePasswordVisibility}
                      >
                        {obscurePassword ? (
                          <ShowPasswordEye className={"h-6 w-6"} />
                        ) : (
                          <HidePasswordEye className={"h-6 w-6"} />
                        )}
                      </button>
                    </div>

                    <label className="text-red-500 text-sm">
                      {passwordError}
                    </label>
                  </div>
                  <div className="flex items-start flex-col w-full gap-1">
                    <label>Confirm New Password</label>
                    <div
                      className={`w-full flex border border-slate-600/50 rounded-md ${
                        confirmPasswordError !== ""
                          ? "border-red-500"
                          : "border-slate-600/50"
                      }`}
                    >
                      <input
                        type={obscureConfirmPassword ? "password" : "text"}
                        value={confirmPassword}
                        placeholder="Confirm new password"
                        onChange={(ev) => setConfirmPassword(ev.target.value)}
                        className={`outline-none rounded-md px-3 py-2 w-full`}
                      />
                      <button
                        className="mr-2"
                        onClick={handleConfirmPasswordVisibility}
                      >
                        {obscureConfirmPassword ? (
                          <ShowPasswordEye className={"h-6 w-6"} />
                        ) : (
                          <HidePasswordEye className={"h-6 w-6"} />
                        )}
                      </button>
                    </div>

                    <label className="text-red-500 text-sm">
                      {confirmPasswordError}
                    </label>
                  </div>
                </div>
                <br />
                {errorMessage && (
                  <div className="text-center text-md text-red-500">
                    {errorMessage}
                  </div>
                )}

                {/* <br /> */}
                <MainButton
                  label={"Change Password"}
                  onClick={onButtonClick}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPassword;

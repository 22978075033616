const VirtualCardShimmer = ({ className, rounded }) => {
  return (
    <div className={className}>
      <div className={`relative overflow-hidden w-full h-full ${rounded}`}>
        <div className="absolute inset-0 bg-gradient-to-r from-transparent to-slate-300 animate-shimmer w-full h-full rounded-lg"></div>
      </div>

      <style>
        {`
          @keyframes shimmerAnimation {
            to {
              transform: translateX(100%);
            }
          }

          .animate-shimmer {
            animation: shimmerAnimation 1.0s infinite linear;
          }
        `}
      </style>
    </div>
    // <Shimmer height={height} width={width} className={`bg-slate-500 text-white w-full`}></Shimmer>
  );
};

export default VirtualCardShimmer;

import React from "react";
import {
  AirBnb,
  Amazon,
  Disnep,
  Netflix,
  Spotify,
  UdemyFullLogo,
} from "../assets";

const HorizontalScroll = () => {
  return (
    <div className="scroll-container flex items-center overflow-x-hidden gap-2 py-6">
      <div className="scroll-content flex items-center gap-8">
        <img className="h-6 sm:h-8 object-cover" src={Amazon} alt="Amazon" />
        <img
          className="h-10 sm:h-20 object-cover"
          src={Netflix}
          alt="Netflix"
        />
        <img
          className="h-8 sm:h-16 object-cover mix-blend-color-burn"
          src={Spotify}
          alt="Spotify"
        />
        <img
          className="h-8 sm:h-16 object-cover mix-blend-color-burn"
          src={Disnep}
          alt="Disney"
        />
        <img
          className="h-10 sm:h-20 object-cover mix-blend-color-burn"
          src={AirBnb}
          alt="AirBnb"
        />
        <img
          className="h-6 sm:h-8 object-cover mix-blend-color-burn"
          src={UdemyFullLogo}
          alt="Udemy"
        />

        {/* Duplicate the images to create a continuous loop effect */}
        <img
          className="h-6 sm:h-8 object-cover px-3"
          src={Amazon}
          alt="Amazon"
        />
        <img
          className="h-10 sm:h-20 object-cover"
          src={Netflix}
          alt="Netflix"
        />
        <img
          className="h-8 sm:h-16 object-cover mix-blend-color-burn"
          src={Spotify}
          alt="Spotify"
        />
        <img
          className="h-8 sm:h-16 object-cover mix-blend-color-burn"
          src={Disnep}
          alt="Disney"
        />
        <img
          className="h-10 sm:h-20 object-cover mix-blend-color-burn"
          src={AirBnb}
          alt="AirBnb"
        />
        <img
          className="h-6 sm:h-8 object-cover mix-blend-color-burn"
          src={UdemyFullLogo}
          alt="Udemy"
        />
      </div>
    </div>
  );
};

export default HorizontalScroll;

const CardInfo = () => {
  return (
    <div className=" w-full   min-w-[290px] p-2 sm:p-4 sm:px-6  bg-white rounded-md shadow">
      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-3 md:grid-cols-2 lg:grid-cols-3  gap-6 justify-between ">
          <div>
            <div className="text-slate-500 text-sm">Application Fee</div>
            <div className="text-slate-600 text-md">2 USD</div>
          </div>
          <div>
            <div className="text-slate-500 text-sm">Monthly Fee</div>
            <div className="text-slate-600 text-md">1 USD</div>
          </div>

          <div>
            <div className="text-slate-500 text-sm">Card limit</div>
            <div className="text-slate-600 text-md">3</div>
          </div>
          <div>
            <div className="text-slate-500 text-sm">Monthly limit</div>
            <div className="text-slate-600 text-md">1,000 USD</div>
          </div>
          <div>
            <div className="text-slate-500 text-sm">Recharge Fee</div>
            <div className="text-slate-600 text-md">3.5% USD</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardInfo;

import { useNavigate } from "react-router-dom";
const Error = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div className="w-[50%] flex flex-col itmes-center">
        <div className="w-full flex flex-col items-center">
          <h1>404</h1>
          <p> Page Doesn't found</p>
          <button
            className="bg-gradient-to-r bg-animate  from-[#2cb4d5]  to-[#1a6c80] w-[200px] rounded-full text-white py-3 text-lg"
            onClick={() => navigate("/")}
          >
            Go To HomePage
          </button>
        </div>
      </div>
    </div>
  );
};
export default Error;

import { Navbar } from "../sections";
import { Telegram } from "../components";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { primaryColor } from "../constants/constants";

const Layout = () => {
  const location = useLocation();
  const [accountVisibility, setAccountVisibility] = useState(false);
  const [token, setToken] = useState("");
  const [hideTelegram, setHideTelegram] = useState(false);
  const navigate = useNavigate();
  const MyAccountClick = (value) => {
    setAccountVisibility(value);
  };
  useEffect(() => {
    let token = localStorage.getItem("token");
    setToken(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (token != null || location.pathname.startsWith("/virtual-cards")) {
      // console.log(token);
      // setHideFooter(true);
      setHideTelegram(true);
    } else {
      // setHideFooter(false);
      setHideTelegram(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, token]);

  function logout() {
    localStorage.removeItem("token");
    setAccountVisibility(!accountVisibility);
    navigate("/virtual-cards");
    setToken("");
    window.location.reload();
  }
  return (
    <div className="w-full min-h-screen flex flex-col">
      <div className="sticky z-50 top-0 w-full h-24 backdrop-blur-xl">
        <div className=" w-full flex justify-center z-50 ">
          <div
            className={`w-[90%] top-5 fixed md:w-[80%] border border-[#2cb4d5]
              rounded-[35px]
             bg-white/80 backdrop-blur-sm z-50`}
          >
            <Navbar MyAccountClicked={MyAccountClick} token={token} />
          </div>
          <div className="w-[90%] fixed  md:w-[80%] flex justify-end px-2 z-50 ">
            {accountVisibility && (
              <div
                className={`absolute top-20 rounded-lg border border-[${primaryColor}] min-w-[250px] px-6 p-4 bg-white`}
              >
                <ul className="flex flex-col justify-center gap-2 rounded-lg">
                  <NavLink
                    to={"my-profile"}
                    onClick={() => setAccountVisibility(false)}
                    className="cursor-pointer text-slate-500"
                  >
                    My Profile
                  </NavLink>
                  {/* <li className="cursor-pointer text-slate-500"></li> */}
                  <hr />
                  <li
                    className="cursor-pointer text-slate-500"
                    onClick={logout}
                  >
                    Log out
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <div className="flex flex-col flex-grow "> */}
      <Outlet />
      {/* </div> */}
      {/* {!hideFooter && <Footer />} */}
      {!hideTelegram && (
        <div className="w-[60px] h-[60px] bg-[#2cb4d5] fixed bottom-14 right-14 rounded-full flex items-center justify-center ">
          <div className="relative flex items-center justify-center">
            <div className="w-[45px] h-[45px] border border-[#2cb4d5] rounded-full animate-ping absolute"></div>
            <div className="w-[60px] h-[60px] border border-[#2cb4d5]  rounded-full animate-ping absolute"></div>
            <a
              href="https://t.me/yehadigital"
              target="_blank"
              rel="noopener noreferrer"
              className="w-[60px] h-[60px] bg-[#2cb4d5] text-white fixed bottom-14 right-14 rounded-full flex items-center justify-center z-20"
            >
              <Telegram
                style={{ width: "1.5rem", height: "1.5rem", color: "white" }}
              />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default Layout;

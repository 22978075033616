import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { token } from "../constants/constants";
import { LoginContext } from "./loginContext";

const ProtectedRoute = ({ element }) => {
  const { user } = useContext(LoginContext);

  if (!user || !token) {
    return <Navigate to="/virtual-cards" />;
  }

  return element;
};

export default ProtectedRoute;

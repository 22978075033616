import Copy from "./Icons/Copy";
import CopiedIcon from "./Icons/Copied";

const AddressDetail = ({ title, value, isCopied, onCopy }) => {
  const handleCopy = () => {
    onCopy();
  };
  return (
    <>
      <div className="flex justify-between p-2 border-b">
        <div className="text-slate-500">{title} :</div>
        <div className="flex">
          <div className="text-slate-700">{value}</div>
          <button
            onClick={() => handleCopy()}
            className="ml-2  text-slate-700 px-2 py-1 rounded"
          >
            {isCopied ? <Copy className={"h-5 w-5"} /> : <CopiedIcon />}
          </button>
        </div>
      </div>
    </>
  );
};

export default AddressDetail;

const OnlineServices = ({ image, title }) => {
  return (
    <div className="p-2 flex gap-2 bg-white rounded-3xl border border-[#1a9aba30] px-4">
      <div>
        <img src={image} alt="" className="h-6 " />
      </div>
      <div className="text-slate-600">{title}</div>
    </div>
  );
};
export default OnlineServices;

import { useState, useEffect } from "react";
import { Menu } from "../components";
import { NavLink, useNavigate } from "react-router-dom";
import { logo } from "../assets";
import { primaryColor } from "../constants/constants";
import { fetchUserInfo } from "../api/UserInfo";

const Navbar = ({ MyAccountClicked, token }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [user, setUser] = useState(null);
  // const [token, setToken] = useState("");
  const [accountVisibility, setAccountVisibility] = useState(true);
  // const token = localStorage.getItem("token");

  const navigate = useNavigate();

  const handleMenuToggle = () => {
    MyAccountClicked(false);
    if (window.innerWidth <= 768) {
      setIsExpanded(!isExpanded);
    }
  };

  const myAccount = () => {
    setAccountVisibility(!accountVisibility);
    MyAccountClicked(accountVisibility);
    if (window.innerWidth <= 768) {
      setIsExpanded(false);
      MyAccountClicked(true);
    } // handleMenuToggle();
  };
  const getUserI = async (token) => {
    try {
      const info = await fetchUserInfo(token);
      setUser(info.data);
      // console.log(info);
      return;
    } catch (e) {
      return;
    }
  };

  useEffect(() => {
    getUserI(token);
    if (window.innerWidth >= 768) {
      setIsExpanded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="relative w-full">
        <nav
          className={`w-full flex flex-col  md:flex-row justify-between md:items-center px-10 relative ${
            isExpanded ? "bg-white" : "bg-transparent"
          }  rounded-[35px] `}
        >
          <div className="h-[70px]  flex items-center hover:cursor-pointer">
            <div
              onClick={() => navigate("/")}
              className="flex gap-1 items-center"
            >
              <img src={logo} alt="logo" className="h-7 w-7" />
              <h2 className="font-bold text-[#1a6c80] ">YEHADIGITAL</h2>
            </div>
          </div>
          <div className="absolute right-5 my-auto h-[70px]  flex items-center">
            <div className="w-8 h-8 ">
              {token !== null ? (
                <>
                  <div className="relative ">
                    <div
                      onClick={myAccount}
                      className={`h-10 w-10 bg-[${primaryColor}]/30 rounded-full border flex justify-center items-center text-md text-slate-600 border-[${primaryColor}] cursor-pointer`}
                    >
                      {user && user.first_name.charAt(0)}
                    </div>
                  </div>
                </>
              ) : (
                <div onClick={handleMenuToggle} className="w-8 h-8 md:hidden ">
                  <Menu style={{ height: "1.5rem", width: "1.5rem" }} />
                </div>
              )}
            </div>
          </div>
          <ul
            className={`md:items-center gap-4 flex flex-col md:flex-row ${
              isExpanded ? "h-auto py-4" : "h-0"
            } overflow-hidden transition-all duration-100`}
          >
            {/* <NavLink
              onClick={handleMenuToggle}
              to="/"
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "text-[#2cb4d5]" : ""
              }
            >
              Home
            </NavLink>
            <NavLink
              onClick={handleMenuToggle}
              to="/about"
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "text-[#2cb4d5]" : ""
              }
            >
              About
            </NavLink>
            <NavLink
              onClick={handleMenuToggle}
              to="/contact"
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "text-[#2cb4d5]" : ""
              }
            >
              Contact
            </NavLink>
            <NavLink
              onClick={handleMenuToggle}
              to={"/virtual-cards"}
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "text-[#2cb4d5]" : ""
              }
            >
              Virtual cards
            </NavLink> */}
            {token !== null ? (
              <>
                <div></div>
              </>
            ) : (
              <>
                <NavLink
                  onClick={handleMenuToggle}
                  to="/sign-up"
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "text-[#2cb4d5]" : ""
                  }
                >
                  Sign up
                </NavLink>
                <NavLink
                  onClick={handleMenuToggle}
                  to="login"
                  className={({ isActive, isPending }) =>
                    isPending
                      ? "pending text-slate-300"
                      : isActive
                      ? "bg-[#2cb4d5] text-white px-5 py-1 rounded-xl shadow-none text-center"
                      : "text-white border bg-[#1f7e95] px-5 py-1 rounded-xl shadow-none text-center"
                  }
                >
                  Login
                </NavLink>
              </>
            )}
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Navbar;

import CheckIcon from "./Check";
// 1a6c80
const CheckedText = ({ title }) => {
  return (
    <div className="flex items-center gap-1">
      <CheckIcon styles={{ height: "1.5rem", width: "1.5rem",color:"#1a6c80"}} />
      <h1 className="font-medium text-md md:text-lg text-slate-700">{title}</h1>
    </div>
  );
};

export default CheckedText;

import { useBackButton } from "../Contexts/BackButtonContext";
import ArrowLeft from "./Icons/ArrowLeft";
import CloseIcon from "./Icons/CloseIcon";

const Modal = ({ isOpen, onClose, children }) => {
  const { step, setStep, selectedPayment, setSelecedPayment } = useBackButton();

  if (!isOpen) {
    return null;
  }
  const handleOverlayClick = (event) => {
    if (event.target.classList.contains("overlay")) {
      onClose();
    }
  };

  const handleArrowBack = () => {
    if (selectedPayment === "") {
      onClose();
    }
    if (step > 0) {
      setStep((prev) => {
        return prev - 1;
      });
    }
    if (step === 0) {
      setSelecedPayment("");
      onClose();
    }
  };

  // trial

  return (
    <div
      onClick={handleOverlayClick}
      className="fixed inset-0 flex items-center justify-center z-50 overlay overflow-hidden "
    >
      <div className="bg-black opacity-70 fixed inset-0 overlay cursor-pointer"></div>
      <div className="bg-white mt-3 mx-3 p-1 w-[90%] sm:w-auto md:p-6 rounded-md shadow-md z-10">
        <div className="w-full h-full flex flex-col">
          <div className="w-full flex justify-between">
            <button onClick={handleArrowBack}>
              <ArrowLeft className={"w-6 h-6"} />
            </button>
            <button
              className="text-black hover:text-gray-800"
              onClick={onClose}
            >
              <CloseIcon />
            </button>
          </div>

          <div className="w-full h-full text-end"></div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
